<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ $t('module.tindakan') }}</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="tindakan.id"
                />
                <div class="form-group row" v-if="tindakan.parent">
                    <label class="col-lg-4 col-form-label">{{ $t('common.parentTindakan') }}:</label>
                    <label class="col-lg-6 col-form-label">{{tindakan.parent.nama}}</label>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">{{ $t('common.latest') }}:</label>
                    <div class="col-lg-6">
                        <div class="kt-checkbox-inline">
                            <label class="kt-checkbox">
                                <input
                                type="checkbox"
                                v-model="tindakan.latest"
                                /> {{ $t('common.latest') }}
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">{{ $t('common.name') }}:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="tindakan.nama"
                            v-validate="'required'"
                            data-vv-as="Nama"
                            name="nama"
                            :class="{'is-invalid': errors.has('nama') }"/>
                        <div v-show="errors.first('nama')" class="invalid-feedback">{{ errors.first('nama') }}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >{{ $t('button.close') }}</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">{{ $t('button.save') }}</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import Tindakan from "../../../model/tindakan-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const TindakanRepository = RepositoryFactory.get("tindakan");
export default {
  components: {

  },
  data() {
    return {
        tindakan : new Tindakan()
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object },
    modalParent: { type: Object },
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    clearData: function () {
        $("#" + this.modalName).modal("hide");
        this.$emit("update:showModal", false);
    },
    updateData: function (data) {
        this.tindakan = data;
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          if (vx.tindakan.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data Tindakan akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.tindakan);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data Tindakan akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.tindakan);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await TindakanRepository.updateTindakan(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Tindakan berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Tindakan gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;
      await TindakanRepository.createTindakan(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Tindakan berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Tindakan gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
        this.updateData(this.modalData);
    }
    if (this.modalParent) {
        this.tindakan.parent = this.modalParent;
    }
  }
}
</script>

<style>

</style>
